import React from 'react'

const FooterSplash = () => {    

    return (<div className="row white-text" style={{position:'relative',margin:'0px',fontSize:'14px',padding:'15px 30px',background:'#132C51'}}>
            <div className="col s6 left-align">
                Created by <a href="https://www.brandbacker.com" target="_blank" className="white-text">BrandBacker</a>
            </div>
            <div className="col s6 right-align">
                Copyright &copy; {new Date().getFullYear()}, GoMakeIt Labs, Inc. All Rights Reserved
            </div>
        </div>
    );
};

export default FooterSplash;