import React from 'react'
import $ from "jquery";
import {axiosInstance, closeAllModals} from "../../utils";
import { useState, useContext, useEffect, useRef, useCallback } from "react";

let bbmid = 0;

const WaitlistForm = () => {    

    const [pageSet, setPage] = useState(false);

    function validateEmailText(emailtext) {
        var validRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
        if (emailtext.match(validRegex)) {
          return 'pass';
        } else {
          return 'fail';
        }
    }

    function saveWaitlistEmail() {
        let waitlist_email = $("#signup-email").val();

        var addData = new Object();
        addData.email = encodeURIComponent(waitlist_email);
        addData.bbID = encodeURIComponent(bbmid);
        
        if(validateEmailText(waitlist_email) == 'fail') {
            alert('Enter a Valid Email Address');
        } else {
            $("#signup-submit-button1").hide();
            $("#signup-submit-loading1").show();
            axiosInstance.post('member/waitlist_add/',addData).then(result => {
                if(Object.keys(result.data).length > 0) {
                    setTimeout(function() {
                        $("#signup-waitlist1").hide();
                        $("#signup-waitlist2").show();
                        $("#signup-submit-button1").show();
                        $("#signup-submit-loading1").hide();
                    },2000);
                }
            }).catch(error => {
                console.log(error);
            });
        }
    }

    function saveWaitlistAdditional() {
        let waitlist_email = $("#signup-email").val();
        let proposal_numbers = $("#signup-proposal-number").val();
        let project_numbers = $("#signup-project-number").val();
        let collab_how = $("#signup-collaboration-how").val();
        let collab_issues = $("#signup-collaboration-issues").val();
        let creator_issues = $("#signup-creator-issues").val();

        var qnumarr = new Array();
        var qnamearr = new Array();
        var qresparr = new Array();
        var qresplongarr = new Array();

        if(proposal_numbers.length > 0) {
            qnumarr.push(1);
            qnamearr.push("monthly_proposals_number");
            qresparr.push(proposal_numbers);
            qresplongarr.push("");
        }
        
        if(project_numbers.length > 0) {
            qnumarr.push(2);
            qnamearr.push("monthly_project_numbers");
            qresparr.push(project_numbers);
            qresplongarr.push("");
        }
        
        if(collab_how.length > 0) {
            qnumarr.push(3);
            qnamearr.push("how_manage_collabs");
            qresparr.push("");
            qresplongarr.push(collab_how);
        }
        
        if(collab_issues.length > 0) {
            qnumarr.push(4);
            qnamearr.push("what_issues_collabs");
            qresparr.push("");
            qresplongarr.push(collab_issues);
        }
        
        if(creator_issues.length > 0) {
            qnumarr.push(5);
            qnamearr.push("what_issues_creator");
            qresparr.push("");
            qresplongarr.push(creator_issues);
        }

        if(qnumarr.length > 0) {
            var addData = new Object();
            addData.email = encodeURIComponent(waitlist_email);
            addData.questionNum = qnumarr;
            addData.questionName = qnamearr;
            addData.questionResponse = qresparr;
            addData.questionRespLong = qresplongarr;

            if(validateEmailText(waitlist_email) == 'fail') {
                alert('Enter a Valid Email Address');
            } else {
                $("#signup-submit-button2").hide();
                $("#signup-submit-loading2").show();
                axiosInstance.post('member/waitlist_survey_add/',addData).then(result => {
                    if(Object.keys(result.data).length > 0) {
                        setTimeout(function() {
                            $("#signup-submit-loading2").hide();
                            $("#signup-additional-form").hide();
                            $("#waitlist-info-saved-button").show();
                        },2000);
                    }
                }).catch(error => {
                    console.log(error);
                });
            }
        }
    }   

    function checkForEmailTracker() {
        if(window.location.href.indexOf("em=") >= 0) {
            var tracking_split = window.location.href.split("em=");
            if(tracking_split.length > 1) {
                var idsplit = tracking_split[1].split("i");
                bbmid = idsplit[0];
            }
        }
    }

    if(pageSet == false) {
        setPage(true);
        setTimeout(function(){
            checkForEmailTracker();
        },50);
    }

    return (<div className="row">
                <div id="signup-waitlist1" className="col s10 offset-s1 center-align">
                    <div className="row headline-font" style={{fontSize:'40px',marginTop:'30px'}}>
                        JOIN THE WAITLIST
                    </div>
                    <div className="row">
                        <div className="col s12 m10 offset-m1">
                        Shake will be open to use soon, in the meantime join the waitlist to get early access and help us build the best product for you.
                        </div>
                    </div>
                    <div className="row">
                        <div className="col s12" style={{padding:'10px'}}>
                            <div className="row" style={{margin:'0px'}}>
                                <div className="col s12 m10 offset-m1" style={{padding:'0px'}}>
                                    <div className="row left-align" style={{margin:'0px',padding:"5px"}}>
                                        Your Email
                                    </div>
                                </div>
                            </div>
                            <div className="row" style={{margin:'0px'}}>
                                <div className="col s12 m7 offset-m1" style={{padding:'0px 5px'}}>
                                    <input type="email" id="signup-email" className="browser-default rounded-input z-depth-1 left" style={{width:'100%',marginBottom:'10px'}} />
                                </div>
                                <div className="col s12 m3" style={{padding:'0px 5px'}}>
                                    <div id="signup-submit-button1" className="waves-effect waves-light white-text z-depth-2 left" 
                                        style={{background:'#132C51',width:'100%',borderRadius:'10px',fontSize:'14px',padding:'10px 15px',cursor:'pointer',border:'1px solid #132C51'}}
                                        onClick={()=>saveWaitlistEmail()}>
                                            SUBMIT
                                    </div>
                                    <div id="signup-submit-loading1" className="left" style={{display:'none'}}>
                                        <div className="preloader-wrapper small active" style={{marginTop:'4px'}}>
                                            <div className="spinner-layer spinner-blue-only">
                                            <div className="circle-clipper left">
                                                <div className="circle"></div>
                                            </div><div className="gap-patch">
                                                <div className="circle"></div>
                                            </div><div className="circle-clipper right">
                                                <div className="circle"></div>
                                            </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div id="signup-waitlist2" className="col s10 offset-s1 center-align" style={{display:'none'}}>
                    <div className="row headline-font" style={{fontSize:'40px',marginTop:'30px'}}>
                        YOU'RE ON THE WAITLIST 🎉
                    </div>
                    <div className="row">
                        <div className="col s12 m10 offset-m1">
                        You're now officially on the Shake early user waitlist. We'll be in touch as soon as Shake is ready for you to access. In the meantime let us know how we can help make 
                        Shake a useful product for you. 
                        </div>
                    </div>
                    <div className="row">
                        <div className="col s12" style={{padding:'10px'}}>
                            <div id="signup-additional-form">
                                <div className="row" style={{marginTop:'0px'}}>
                                    <div className="col s12 m10 offset-m1">
                                        <div className="row left-align" style={{margin:'0px',padding:"5px"}}>
                                            How Many Paid Collaboration Proposals Do You <b>RECEIVE</b> Per Month?
                                        </div>
                                    </div>
                                    <div className="col s12 m10 offset-m1">
                                        <input type="text" id="signup-proposal-number" className="browser-default rounded-input z-depth-1 left" style={{width:'100%'}} />
                                    </div>
                                </div>
                                <div className="row" style={{marginTop:'30px'}}>
                                    <div className="col s12 m10 offset-m1">
                                        <div className="row left-align" style={{margin:'0px',padding:"5px"}}>
                                            How Many Paid Collaborations Do You <b>WORK</b> on Per Month?
                                        </div>
                                    </div>
                                    <div className="col s12 m10 offset-m1">
                                        <input type="text" id="signup-project-number" className="browser-default rounded-input z-depth-1 left" style={{width:'100%'}} />
                                    </div>
                                </div>
                                <div className="row" style={{marginTop:'30px'}}>
                                    <div className="col s12 m10 offset-m1">
                                        <div className="row left-align" style={{margin:'0px',padding:"5px"}}>
                                            How Do You Currently Manage Your Paid Collaborations (Email, Software, Agent, etc)? 
                                        </div>
                                    </div>
                                    <div className="col s12 m10 offset-m1">
                                        <textarea id="signup-collaboration-how" className="browser-default rounded-input z-depth-1 left" style={{width:'100%',height:'70px'}}></textarea>
                                    </div>
                                </div>
                                <div className="row" style={{marginTop:'30px'}}>
                                    <div className="col s12 m10 offset-m1">
                                        <div className="row left-align" style={{margin:'0px',padding:"5px"}}>
                                            What are some of the biggest issues when working on Paid Collaborations?
                                        </div>
                                    </div>
                                    <div className="col s12 m10 offset-m1">
                                        <textarea id="signup-collaboration-issues" className="browser-default rounded-input z-depth-1 left" style={{width:'100%',height:'70px'}}></textarea>
                                    </div>
                                </div>
                                <div className="row" style={{marginTop:'30px'}}>
                                    <div className="col s12 m10 offset-m1">
                                        <div className="row left-align" style={{margin:'0px',padding:"5px"}}>
                                            What are some other issues you run into running your creator business?
                                        </div>
                                    </div>
                                    <div className="col s12 m10 offset-m1">
                                        <textarea id="signup-creator-issues" className="browser-default rounded-input z-depth-1 left" style={{width:'100%',height:'70px'}}></textarea>
                                    </div>
                                </div>
                            </div>
                            <div className="row" style={{marginTop:'30px'}}>
                                <div className="col s10 m6 l4 offset-s1 offset-m3 offset-l4 center-align" style={{padding:'0px'}}>
                                    <div id="signup-submit-button2" className="waves-effect waves-light white-text z-depth-2 center-align" 
                                        style={{background:'#132C51',width:'100%',borderRadius:'10px',fontSize:'16px',padding:'10px 15px',cursor:'pointer',border:'1px solid #132C51'}}
                                        onClick={()=>saveWaitlistAdditional()}>
                                            SUBMIT
                                    </div>
                                    <div id="signup-submit-loading2" className="center-align" style={{display:'none'}}>
                                        <div className="preloader-wrapper small active" style={{marginTop:'4px'}}>
                                            <div className="spinner-layer spinner-blue-only">
                                            <div className="circle-clipper left">
                                                <div className="circle"></div>
                                            </div><div className="gap-patch">
                                                <div className="circle"></div>
                                            </div><div className="circle-clipper right">
                                                <div className="circle"></div>
                                            </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div id="waitlist-info-saved-button" className="waves-effect waves-light white-text z-depth-2 center-align" 
                                            style={{width:'100%',borderRadius:'10px',fontSize:'16px',padding:'10px 15px',cursor:'pointer',border:'3px solid green', background:'green',display:'none'}} >
                                            RESPONSE SAVED!
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
    );
};

export default WaitlistForm;