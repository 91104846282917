import React from 'react'
import { useState, useContext, useEffect, useRef, useCallback } from "react";
import {axiosInstance, closeAllModals} from "../../utils";

import { 
    WaitlistForm,
    SplashHeaderStealth, 
    FooterSplash } from '../../components'

let bb_member_id = 0;
let bb_email_id = 0;
let open_tracked = 0;

export const SplashStealth = (props) => {

    const [pageSet, setPage] = useState(false);

    useEffect(() => {
        document.body.style.backgroundColor="#4360CD";
      }, []);

    document.addEventListener('DOMContentLoaded', function() {
        var elems = document.querySelectorAll('.modal');
        var instances = window.M.Modal.init(elems);
    });

    function openWaitlistForm() {
        var signupbox = document.getElementById("waitlist-form-modal");
        var signupbox_instance = window.M.Modal.getInstance(signupbox);
        signupbox_instance.open();
    }

    function checkForEmailTracker() {
        if((window.location.href.indexOf("em=") >= 0)&&(open_tracked == 0)) {
            var tracking_split = window.location.href.split("em=");
            if(tracking_split.length > 1) {
                var idsplit = tracking_split[1].split("i");
                bb_member_id = idsplit[0];
                if(idsplit.length > 1) {
                    bb_email_id = idsplit[1];
                }
                sendEmailTracking();
            }
        }
    }

    function sendEmailTracking() {
        var addData = new Object();
        addData.emailID = encodeURIComponent(bb_email_id);
        addData.actionID = 'O'
        addData.bbID = encodeURIComponent(bb_member_id);

        axiosInstance.post('member/waitlist_track/',addData).then(result => {
            open_tracked = 1;
        }).catch(error => {
            console.log(error);
        });
    }

    if(pageSet == false) {
        setPage(true);
        setTimeout(function(){
            checkForEmailTracker();
        },50);
    }

    return (
    <><SplashHeaderStealth />
        <div className="row" style={{position:'relative',padding:'0px',margin:'0px',paddingBottom:'80px'}}>
            <div className="col s12 l6" style={{padding:'0px 40px',display:'none'}}>
                <img src="./assets/global/handshake.gif" style={{width:'100%',borderRadius:'20px'}} />
            </div>
            <div className="col s12 l6 white-text center-align" style={{padding:'0px 40px'}}>
                <div className="row headline-font" style={{marginTop:'4vh',marginBottom:'0px',padding:"20px",lineHeight:'1.25',fontSize:'56px'}}>
                    Take Your Creator Business to the Next Level
                </div>
                <div className="row subheadline-font" style={{marginBottom:'60px',fontSize:'28px',marginLeft:'10%',width:'80%'}}>
                    The creator tools you need to earn more, save time, & look professional
                </div>
                <div className="row center-align">
                    <div className="waves-effect waves-light white-text z-depth-2 left hide-on-med-and-down show-on-large modal-trigger" 
                        style={{background:'#132C51',width:'40%',marginLeft:'30%',borderRadius:'10px',fontSize:'24px',padding:'15px 20px',cursor:'pointer',border:'1px solid #132C51'}}
                        data-target="waitlist-form-modal" >
                        LET'S SHAKE
                    </div>
                    <div className="waves-effect waves-light white-text z-depth-2 left show-on-medium-and-down hide-on-large-only modal-trigger" 
                        style={{background:'#132C51',width:'80%',marginLeft:'10%',borderRadius:'10px',fontSize:'24px',padding:'15px 20px',cursor:'pointer',border:'1px solid #132C51',marginBottom:"50px"}} 
                        data-target="waitlist-form-modal">
                        LET'S SHAKE
                    </div>
                </div>
            </div>
            <div className="col s12 l6 transparent" id="contentpeoplegrid" style={{marginTop:'20px',marginLeft:'0px',height:'64.5vh'}}>
                <div className="col s4" style={{height:'100%',textAlign:'center',overflow:'hidden'}}>
                    <div style={{height:'48.5%',marginBottom:'5%',width:'100%',textAlign:'center',overflow:'hidden',borderRadius:'5px',
                    background: 'url(assets/samples/creator_yoga_splash.jpg)', backgroundSize:'cover', backgroundPosition:'center'}}>
                    </div>
                    <div style={{height:'48.5%',width:'100%',textAlign:'center',overflow:'hidden',borderRadius:'5px',
                    background: 'url(assets/samples/creator_diy_splash.jpg)', backgroundSize:'cover', backgroundPosition:'center'}}>
                    </div>
                </div>
                <div className="col s4" style={{height:'99%',textAlign:'center',overflow:'hidden',borderRadius:'5px',
                background: 'url(assets/samples/creator_chef_splash.jpg)', backgroundSize:'cover',backgroundPosition:'center'}}>
                </div>
                <div className="col s4" style={{height:'100%',textAlign:'center',overflow:'hidden'}}>
                    <div style={{height:'48.5%',marginBottom:'5%',width:'100%',textAlign:'center',overflow:'hidden',borderRadius:'5px',
                        background: 'url(assets/samples/creator_plants_splash.jpg)', backgroundSize:'cover', backgroundPosition:'center'}}>
                    </div>
                    <div style={{height:'48.5%',width:'100%',textAlign:'center',overflow:'hidden',borderRadius:'5px',
                    background: 'url(assets/samples/creator_cooking_splash.jpg)', backgroundSize:'cover', backgroundPosition:'center'}}>
                    </div>
                </div>
            </div>
        </div>
        <FooterSplash />
        <div id="waitlist-form-modal" className="modal page-modal-medium">
            <WaitlistForm />
        </div>
    </>
    );
};