import { React, useEffect, useState} from "react";
import './stylesheets/global.css'
import { useSelector, useDispatch } from "react-redux";
import { HashRouter, Routes, Route } from "react-router-dom";

import {
  Splash,
  SplashStealth,
  RateCardPublic,
  RateCardCreator,
  ProposalManager,
  ProjectManager,
  ProjectManagerPublic,
  PaymentsManager,
  Settings,
} from "./pages/"
import $ from "jquery";

let pagehash = '';

function App() {

  const [currentLocation, setCurrentLocation] = useState('');

  useEffect(() => {  
    window.addEventListener("hashchange",
    function(){ 
      if(pagehash !== window.location.hash) {
        
      }
    }, false);
    window.onpopstate = e => {
      if(pagehash !== window.location.hash) {
        
      }
    }
  }, []);

  /* Check For Changes in Signup Loading */
  useEffect(() => {
    if (window !== undefined) {
      
    }
  });


  // TODO: replace with proper navigation
  const router = {
    splash: <Splash />,
    ratecard: <RateCardPublic />,
    ratecard_setup: <RateCardCreator />,
    proposals: <ProposalManager />,
    manager: <ProjectManager />,
    project: <ProjectManagerPublic />,
    payments: <PaymentsManager />,
    settings: <Settings />,
  };

  return (
    <div className="App">
      <HashRouter basename="/">
        <Routes>
          {/* <Route path="payments" 
            element={<PaymentsManager />} 
          />
          <Route path="project" 
            element={<ProjectManagerPublic />} 
          />
          <Route path="manager" 
            element={<ProjectManager />} 
          />
          <Route path="ratecard_creator" 
            element={<RateCardCreator />} 
          />
          <Route path="ratecard" 
            element={<RateCardPublic />} 
          />
          <Route path="settings" 
            element={<Settings />} 
          />
          <Route path="splash2" 
            element={<Splash />} 
          /> */}
          <Route path="/" 
            element={<SplashStealth />} 
          />
          <Route path="*" 
            element={<SplashStealth />} 
          />
        </Routes>
      </HashRouter>
    </div>
  );
}

export default App;
