import React from 'react'

const SplashHeaderStealth = () => {    

    return (<nav className="transparent z-depth-0" style={{height:'100px'}}>
                <div className="nav-wrapper" style={{padding:'15px 30px 10px 30px'}}>
                    <a href="/" className="brand-logo">
                        <img src="/assets/global/shake_logo_white.png" height="45px" style={{marginTop:'5px'}}/>
                    </a>
                    <ul id="nav-mobile" className="right hide-on-med-and-down white-text">
                        <li><a data-target="waitlist-form-modal" className="white-text modal-trigger">Signup</a></li>
                    </ul>
                </div>
            </nav>
    );
};

export default SplashHeaderStealth;