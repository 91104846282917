import axios from 'axios'

const BASE_API_URL = 'https://data.shake.pm/api/v1/';

export const axiosInstance = axios.create({
    baseURL: BASE_API_URL,
    timeout: 60000,
    headers: {
        'Content-Type': 'application/json',
        'accept': 'application/json',
    }
});